require('default-passive-events');

import './bootstrap';
import VueCurrencyFilter from "vue-currency-filter";
import clampy from '@clampy-js/vue-clampy';
import Vue from 'vue';
import VueCookie from "vue-js-cookie";
import VueSocialSharing from 'vue-social-sharing';
import VueClipboard from "vue-clipboard2";
import store from "./store/store";

Vue.use(clampy);
Vue.use(VueCookie);
Vue.use(VueCurrencyFilter);
Vue.use(VueSocialSharing);
Vue.use(VueClipboard);

Vue.component('searchmask', () => import('./components/Search/Searchmask.vue'));

Vue.component('header-icons', () => import('./components/Bookmarks/HeaderIcons.vue'));
Vue.component('sidebar', () => import('./components/Bookmarks/Sidebar.vue'));
Vue.component('bookmark-button', () => import('./components/Bookmarks/BookmarkButton.vue'));
Vue.component('share-menu', () => import('./components/Bookmarks/ShareMenu.vue'));
Vue.component('bookmark-list', () => import('./components/Bookmarks/Bookmarklist.vue'));
Vue.component('last-seen-button', () => import('./components/Bookmarks/LastSeenButton.vue'));

new Vue({
    store,
    el: '#app'
});